<template>
  <div class="signup-form">
    <form @submit.prevent="signupUser">
      <div class="form-group">
        <div v-if="formError" class="error-container">
          <p class="error">{{ formError }}</p>
        </div>
        <div class="form-control">
          <div class="label-container">
            <label class="signup-label">{{ $t("name") }}</label>
            <span class="required"></span>
          </div>
          <input
            id="userName"
            type="text"
            v-model="userName"
            autocomplete="off"
            :placeholder="`${namePlaceholder}`"
            maxlength="32"
          />
        </div>
 <!-- More form controls for mobile -->
        <div class="form-control" v-if="inputtype === 'Mobile'">
          <div class="label-container">
            <label class="signup-label">{{ $t("mobilenumber") }}</label>
            <span class="required">*</span>
          </div>
          <div class="input-container">
            <input ref="mobileno" id="userMobileno" type="text" v-model="userMobileno" placeholder="mobilenumber" />
            <span :class="localDisplayLang === 'ara' ? 'rtl-change' : 'change'">
              <span class="input-span" @click="changeFrom">{{ $t("change") }}</span>
            </span>
          </div>
        </div>

  <!-- More form controls for emailid -->
  <div class="form-control" v-if="!isMobileSignupAllowed || inputtype === 'Email'">
          <div class="label-container">
            <label class="signup-label">{{ $t("email id") }}</label>
            <span class="required">*</span>
          </div>
          <div class="input-container">
            <input
              ref="email"
              id="userEmail"
              :class="localDisplayLang === 'ara' ? 'py-l' : 'py-r'"
              type="email"
              v-model="userEmail"
              placeholder="Email"
              maxlength="64"
            />
            <span :class="localDisplayLang === 'ara' ? 'rtl-change' : 'change'">
              <span class="input-span" @click="changeFrom">{{ $t("change") }}</span>
            </span>
          </div>
        </div>
        <div class="form-control">
          <div class="label-container" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
            <label class="signup-label">{{ $t("password") }}</label>
            <span class="required">*</span>
          </div>
          <div class="input-container" dir="ltr">
            <input
              id="userPassword"
              type="password"
              v-model="password"
              autocomplete="new-password"
              :placeholder="`${passwordPlaceholder}`"
              @keydown.space.prevent
            />
            <span class="eye">
              <img :src="eyeopen" @click="toggleEye($event)" alt />
            </span>
          </div>
        </div>

        <!-- For Captcha -->

        <div class="form-control">
          <div v-if="captchaImage && appConfig.featureEnabled.isCaptchaEnabled" class="label-container" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
            <label class="signup-label">{{ $t("Please enter the numbers you see in the image") }}</label>
          </div>
          <!-- <img v-if="captchaImage" class="captcha-image" :src="captchaImage" alt="Captcha" /> -->

          <div
            v-if="captchaImage && appConfig.featureEnabled.isCaptchaEnabled"
            :style="{
              backgroundImage: 'url(' + captchaImage + ')',
              'background-repeat': 'no-repeat',
              'background-color': 'white',
              width: '150px',
            }"
            class="captcha-image"
          ></div>
        </div>

        <div v-if="appConfig.featureEnabled.isCaptchaEnabled">
          <button @click="getAnotherCaptcha" class="button-link" type="button">
            {{ $t("Click to refresh if you can not read") }}
          </button>
        </div>

        <div v-if="appConfig.featureEnabled.isCaptchaEnabled" class="form-control">
          <div class="label-container" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
            <!-- <label class="signup-label">{{ $t("The result") }}</label>
            <span class="required">*</span> -->
          </div>
          <div class="input-container"  :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
            <input
              id="captcha"
              type="text"
              v-model="captcha"
              :placeholder="`${captchaPlaceholder}`"
              @keydown.space.prevent
            />
          </div>
        </div>
        <!-- <div class="form-control">
          <div class="label-container">
            <label class="signup-label">{{ $t('age') }}</label>
            <span class="required">*</span>
          </div>
        Show datepicker only if age range flag is set to false-->
        <!-- <date-picker
            v-if="!appConfig.featureEnabled.ageRange"
            v-model="dob"
            width="100%"
            format="YYYY-MM-DD"
            placeholder="yyyy-mm-dd"
            :not-after="new Date()"
            :disabled-date="(date) => date >= new Date()"
        ></date-picker>-->
        <!--Show Age range UI onnly if age range flag is set to true-->
        <!-- <div class="age-range" v-else>
            <div
              class="age-range-item"
              v-for="(item, index) in ageRange"
              :key="index"
              @click="selectAgeRange(item, index)"
            >
              <div>{{ item }}</div>
            </div>
          </div>
        </div>-->
        <!-- <div class="form-control">
          <label class="signup-label">{{ $t('gender') }}</label>
          <div class="radio">
            <input type="radio" id="Male" name="gender" value="MALE" v-model="gender" />
            <label for="Male">
              {{ $t('male') }}
              <span></span>
            </label>
            <input type="radio" id="Female" name="gender" value="FEMALE" v-model="gender" />
            <label for="Female">
              {{ $t('female') }}
              <span></span>
            </label>
            <input type="radio" id="Others" name="gender" value="OTHER" v-model="gender" />
            <label for="Others">
              {{ $t('others') }}
              <span></span>
            </label>
          </div>
        </div>-->
      </div>
      <div class="signup-button">
        <button
          class="button-primary signup"
          :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
          type="submit"
        >
          <span class="btn-txt-color">{{ $t("signup") }}</span>
        </button>
      </div>
      <div class="sign-up-footer">
        <p>{{ $t("by signing up, you agree to") }}</p>
        <p>
          <span class="company-policies">
            <a
              :href="
                localDisplayLang === 'ara'
                  ? appConfig.providerDetails.termsConditionsUrl.ar
                  : appConfig.providerDetails.termsConditionsUrl.default
              "
              target="_blank"
              >{{ $t("terms of use") }}</a
            >
            <!-- <router-link to="/TermsOfUse">
              <u @click="closeSignup">Terms of Use</u>
            </router-link>-->
          </span>
          {{ $t("and") }}
          <span class="company-policies">
            <a
              :href="
                localDisplayLang === 'ara'
                  ? appConfig.providerDetails.privacyPolicyUrl.ar
                  : appConfig.providerDetails.termsConditionsUrl.default
              "
              target="_blank"
              >{{ $t("privacy policy") }}</a
            >
            <!-- <router-link to="/PrivacyPolicy">
              <u @click="closeSignup">Privacy Policy</u>
            </router-link>-->
          </span>
        </p>
      </div>
    </form>
        <!-- Error Popup -->
    <div v-if="showErrorPoup" class="popup-overlay">
      <div class="popup-container">
        <p class="popup-message">{{ $t("emailAlreadyExistsMessage") }}</p>
        <div class="popup-actions">
          <button @click="redirectToLogin" class="button-primary">{{ $t("login") }}</button>
          <button @click="continueSignup" class="button-primary">{{ $t("continue") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utility from "@/mixins/Utility";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import facebookPixelAnalytics from "@/mixins/facebookPixelAnalytics.js";
import cleverTap from "@/mixins/clevertap.js";
import branchIO from "@/mixins/branchEvents.js";
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/eventBus";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { _providerId } from "@/provider-config.js";

export default {
  props: ["input", "inputtype", "isMobileSignupAllowed" ],
  data() {
    return {
      userName: null,
      userEmail: null,
      userMobileno: null,
      password: null,
      dob: null,
      gender: null,
      formError: null,
      showDateFilter: false,
      eyeopen: require("@/assets/icons/eye.svg"),
      eyeClose: require("@/assets/icons/eye-close.svg"),
      toast: null,
      ageRange: ["13-17", "18-30", "31-45", "45+"],
      providerUniqueId: _providerId,
      localDisplayLang: null,
      passwordPlaceholder: "",
      captchaPlaceholder: "",
      namePlaceholder: "",
      captcha: "",
      showErrorPoup: false,

      captchaImage: null,
    };
  },
  computed: {
    ...mapGetters(["appConfig", "gettoken", "appConfig", "getRtl", "country"]),
  },
  watch: {
    formError() {
      setTimeout(() => {
        this.formError = null;
      }, 5000);
    },
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
    //adding age ranges to the array.
    if (this.appConfig.featureEnabled.kidsMode) {
      this.ageRange.unshift("<13");
    }
  },
  mounted() {
    console.log("inputtype", this.inputtype);

    if (this.appConfig.featureEnabled.isCaptchaEnabled) {
      // Get Captcha Image for display
      this.getCaptchaImage();
    }

    this.setupForm();
    this.passwordPlaceholder = this.$t("password");
    this.captchaPlaceholder = this.$t("The result");
    this.namePlaceholder = this.$t("name");
    eventBus.$on("auth-close", () => {
      this.$emit("closePopup");
    });

    eventBus.$on("social-captch-event", (data) => {
      console.log("THIS IS THE SOCIAL -- EVENT -- ", data);

      let info = {
        data,
      };
      info.formType = "socialCaptcha";
      // this.formInfo = info;
      this.$emit("change", info);
    });

    //social signup.
    eventBus.$on("social-signup-response", (payload) => {
      console.log("THE SIGNUP/SOCIAL - PAYLOAD", payload);

      let signup_type = payload.data.app;

      const browser = this.getPlatformType();

      let data = {
        sign_up_mode: signup_type === "FB" ? "Facebook" : signup_type === "Google" ? "Google" : "",
        status: "Success",
        email: "",
        mobile: "",
        platform: this.getPlatform(),
        platform_type: browser.browser,
      };

      // Firebase Analytics Events.
      this.SignupEvent(data);

      // Facebook Pixel Events
      this.faceBookCompleteRegistrationEvent(data);

      //clevertap signup event.
      this.signUpCleverEvent(data);
    });

    //Normal email & password signup.
    eventBus.$on("signup-response", (payload) => {
      let data = {};
      console.log("payload ------ sign up", payload);
      if (payload && payload.response && payload.response.reason) {
        console.log('siign up captcha response=========',payload.response.reason)
        if(payload.response.reason == "Email Already Exist"){
          this.showErrorPoup = true;
        }else {
           this.formError = this.$t(payload.response.reason);
        }

        //analytics event.
        data.sign_up_mode = this.inputtype === "Mobile" ? "Mobile" : "Email";
        data.status = "Failed";
        data.error_reason = payload.response.reason;
        // data.Subscriber_ID = payload.response.success;
        data.email = this.userEmail;
        data.mobile = this.userMobileno;
      } else {
        let template = {};
        // data.Subscriber_ID = payload.response.success;
        if (this.inputtype === "Mobile") {
          if(!this.isMobileSignupAllowed){
            template.formType = "lookup";
            template.input = this.userEmail;
            this.triggerSnackbarEvent();
            this.$emit("change", template);

            //analytics signup email event.
            data.sign_up_mode = "Email";
            data.status = "Success";
            data.email = this.userEmail;
          }else {
            template.formType = "mobile";
            this.triggerSnackbarEvent();
            template.input = this.userMobileno;
            this.$emit("change", template);

            //analytics signup mobile event.
            data.sign_up_mode = "Mobile";
            data.status = "Success";
            data.mobile = this.userMobileno;
          }
        } else if (this.inputtype === "Email") {
          template.formType = "lookup";
          template.input = this.userEmail;
          this.triggerSnackbarEvent();
          this.$emit("change", template);

          //analytics signup email event.
          data.sign_up_mode = "Email";
          data.status = "Success";
          data.email = this.userEmail;
        }

        const browser = this.getPlatformType();

        data.platform = this.getPlatform();

        data.platform_type = browser.browser;

        // Firebase Analytics Events.
        this.SignupEvent(data);

        // Facebook Pixel Events
        this.faceBookCompleteRegistrationEvent(data);

        let login_data = {
          Name: payload.data.subscribername ? payload.data.subscribername : "",
          Identity: payload.response.success ? payload.response.success : "",
          Email: payload.data.email ? payload.data.email : "",
          Phone: payload.data.mobileno ? payload.data.mobileno : "",
          Gender: payload.data.gender ? (payload.data.gender === "MALE" ? "M" : "F") : "",
          DOB: payload.data.dob ? moment(payload.data.dob).format("DD-MM-YYYY") : "",
          Photo: payload.data.picture ? payload.data.picture : "",
          "MSG-email": true,
          "MSG-push": true,
          "MSG-sms": true,
        };

        //clevertap user login sdk event.
        this.loginCleverEvent(login_data);

        //clevertap push user profile.
        // clevertap.profile.push({
        //   Site: {
        //     RegistrationStartDate: this.displayDateFormat()
        //   }
        // });

        //clevertap signup event.
        this.signUpCleverEvent(data);

        //branch signup event
        this.signupBranchEvent(data);
      }
    });
  },
  methods: {
    ...mapActions(["getCaptcha"]),

    getAnotherCaptcha() {
      this.getCaptchaImage();
    },

    getCaptchaImage() {
      this.getCaptcha()
        .then((data) => {
          if (data.reason) {
            this.formError = this.$t(data.reason);
            return;
          }

          console.log("CAPCTHA PAYLOAD ---- ", data);

          this.captchaImage = data.payload;
          this.captchaReferenceId = data.referenceid;
        })
        .catch((error) => {
          console.log("Captcha error -> ", error);
        });
    },

    setupForm() {
      if (this.input) {
        if (this.inputtype === "Mobile") {
          this.userMobileno = this.input;
          this.$refs.mobileno.disabled = true;
          let input = document.getElementById("userMobileno");
          input.style.backgroundColor = "#5C5C5D";
          input.style.opacity = "0.3";
        } else {
          this.userEmail = this.input;
          this.$refs.email.disabled = true;
          let input = document.getElementById("userEmail");
          input.style.backgroundColor = "#5C5C5D";
          input.style.opacity = "0.3";
        }
      }
    },
    changeFrom() {
      let info = {
        formType: "lookup",
      };
      this.$emit("change", info);
    },

    //select age range.
    selectAgeRange(item, index) {
      this.dob = null;
      let dom = document.getElementsByClassName("age-range-item");

      //removing styles from all the grid items.
      for (let i = 0; i < dom.length; i++) {
        dom[i].style.backgroundColor = "transparent";
        dom[i].style.color = "rgba(255,255,255,0.6)";
      }
      // dom.forEach(ele => {
      //   ele.style.backgroundColor = "transparent";
      //   ele.style.color = "rgba(255,255,255,0.6)";
      // });

      //applying styles for particular grid item.
      dom[index].style.backgroundColor = "#000000";
      dom[index].style.color = "#ffffff";

      console.log("age range", item);

      switch (item) {
        case "<13":
          console.log("age is 10", this.getCurrentDate(10));
          this.dob = this.getCurrentDate(10);
          break;
        case "13-17":
          console.log("age is 13", this.getCurrentDate(13));
          this.dob = this.getCurrentDate(13);
          break;
        case "18-30":
          console.log("age is 18", this.getCurrentDate(18));
          this.dob = this.getCurrentDate(18);
          break;
        case "31-45":
          console.log("age is 31", this.getCurrentDate(31));
          this.dob = this.getCurrentDate(31);
          break;
        case "45+":
          console.log("age is 45", this.getCurrentDate(46));
          this.dob = this.getCurrentDate(46);
          break;
        default:
          break;
      }
    },

    signupUser() {
      if (!this.validateName(this.userName) || !this.validateCountryCode()) return;
			let coupon_campaign = localStorage.getItem('campaign-coupon');

      if (this.inputtype === "Email") {
        if (
          !this.validateEmail(this.userEmail) ||
          !this.validatePassword(this.password)
          // || !this.validateDob(this.dob)
        )
          return;
      } else {
        if (
          !this.validateMobileno(this.userMobileno) ||
          !this.validatePassword(this.password)
          //|| !this.validateDob(this.dob)
        ){
          if(!this.isMobileSignupAllowed){
            !this.validateEmail(this.userEmail)
          }
          return;
        }
      }

      let payload = {
        devicetype: "PC",
        subscribername: this.userName,
        country: this.country.CountryCode,
				couponcode: coupon_campaign
      };

      if (this.captcha && this.appConfig.featureEnabled.isCaptchaEnabled) {
        payload.captcha = JSON.stringify({
          text: this.captcha,
          referenceid: this.captchaReferenceId,
        });
      } else if (!this.captcha && this.appConfig.featureEnabled.isCaptchaEnabled) {
        this.formError = this.$t("Please enter Captcha");
        return;
      }

      if (this.inputtype === "Mobile") {
        payload.mobileno = this.userMobileno;
        if(this.userEmail){
          payload.email = this.userEmail;
        }
      } else {
        payload.email = this.userEmail;
      }
      if (this.password) {
        payload.password = this.password;
      }
      if (this.dob) {
        if (!this.appConfig.featureEnabled.ageRange) {
          payload.dob = moment(new Date(this.dob)).format("YYYY-MM-DD");
        } else {
          payload.dob = this.dob;
        }
      }
      if (this.gender) {
        payload.gender = this.gender;
      }

      console.log("signup payload", payload);

      eventBus.$emit("subscriberSignup", payload);
    },

    redirectLookup() {
      let template = {};
      template.formType = "lookup";
      template.input = this.userEmail;
      console.log("template", template);
      this.$emit("change", template);
    },
      redirectToLogin() {
        // Logic to redirect user to login page
      let template = {};
      template.formType = "login";
      template.inputtype = "Email"; 
      template.input = this.userEmail;
      console.log("template", template);
      this.$emit("change", template);
      },
      continueSignup() {
        // Logic to proceed without addressing the duplicate email
        this.showErrorPoup = false;
      },

    triggerSnackbarEvent() {
    if (!this.isMobileSignupAllowed) {
      this.inputtype = "Email"; // Set inputtype to "Email" if condition is met
    }

    let payload = {
      state: true,
      message:
        this.inputtype === "Email"
          ? "Verification link sent to your mail address"
          : "OTP has been sent to your mobile number",
      color: "green",
    };

    eventBus.$emit("snackBarMessage", payload);
  },
    // triggerSnackbarEvent() {
    //   let payload = {
    //     state: true,
    //     message:
    //       this.inputtype === "Email"
    //         ? "Verification link sent to your mail address"
    //         : "OTP has been sent to your mobile number",
    //     color: "green",
    //   };
    //   eventBus.$emit("snackBarMessage", payload);
    // },

    validateDob(dob) {
      this.formError = null;
      if (!dob) {
        this.formError = "Please select age.";
        return false;
      } else if (dob) {
        let d = new Date();
        let currentYear = d.getFullYear();
        let userYear = moment(dob)
          .format("YYYY-MM-DD")
          .split("-")[0];
        if (currentYear - userYear >= 0) {
          if (currentYear - userYear < 13) {
            this.formError = "Age should be greater than 13";
            return false;
          }
        }
      }
      return true;
    },

    closeSignup() {
      this.$emit("closePopup");
    },
  },
  components: { DatePicker },
  mixins: [Utility, googleAnalytics, cleverTap, branchIO, facebookPixelAnalytics],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-container {
  background: #131313;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.popup-message {
  color: #fff;
  margin-bottom: 20px;
}

.popup-actions {
  display: flex;
  gap: 10px;
  justify-content: center;
}

// .button-primary {
//   background-color: #007bff;
//   color: white;
//   padding: 10px 20px;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
// }

// .button-secondary {
//   background-color: #6c757d;
//   color: white;
//   padding: 10px 20px;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
// }
.loading {
  padding-top: 1rem;
}

.captcha {
  &-image {
    margin: 10px 0;
    height: 50px;
  }
}

.signup-button {
  margin-top: 2rem;
  .signup {
    width: 100%;
  }
}

.date-picker {
  position: absolute;
  z-index: 2;
  left: -10%;
}
.mx-datepicker {
  .mx-input-wrapper {
    .mx-icon-calendar {
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
.sign-up-footer {
  color: #7e7e7e;
  margin-top: 1.2rem;
  font-family: $font-regular;
  text-align: center;
  font-size: 0.7rem;
  font-weight: 200;
  line-height: 1.2rem;
  .company-policies {
    font-weight: 700;
    cursor: pointer;
    &:hover {
      color: $clr-light-gd2;
    }
  }
}

.py-l {
  padding-left: 3.4rem !important;
}

.py-r {
  padding-right: 3.4rem !important;
}

.radio input[type="radio"] {
  display: none;
  /*removes original button*/
}

.radio label {
  position: relative;
  padding-right: 1.2rem;
  color: $clr-popup-label;
}

.radio input[type="radio"] + label:before {
  /*styles outer circle*/
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 13px;
  height: 13px;
  border-radius: 11px;
  border: 2px solid $clr-popup-label;
  background-color: transparent;
}

.radio input[type="radio"]:checked + label {
  color: $btn-clr;
  font-weight: bold;
}

.radio input[type="radio"]:checked + label:before {
  border: 2px solid $btn-clr;
}

.radio input[type="radio"]:checked + label > span {
  border-radius: 11px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 5px;
  left: 5px;
  display: block;
  background-color: $btn-clr;
}

.input-container {
  position: relative;
  .rtl-change {
    color: #ffffff;
    position: absolute;
    top: 32%;
    left: 3%;
  }

  .rtl-eye {
    position: absolute;
    top: 35%;
    left: 3%;
    opacity: 0.5;
    img {
      height: 18px;
      width: 18px;
      cursor: pointer;
    }
  }
}
</style>
